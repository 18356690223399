import { ActionType, AppLanguage, ClickOutsideDirective, UnsubscriberClass, IAction } from '@aston/foundation';
import { BreadcrumbComponent, DebugBarComponent } from '@aston/foundation/components';
import { ScrolledByContainerDirective } from '@aston/foundation/pipes';
import { UserPrefsStoreActions } from '@aston/user-prefs';
import { FeatureFlagsModule } from '@aston/feature-flags';
import { toObservable } from '@angular/core/rxjs-interop';
import { Component, OnInit, inject, viewChild } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ITheme, ThemesModule } from '@aston/themes';
import { combineLatest, Observable } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngrx/store';

import { NotificationsPanelComponent } from '../../components/notifications-panel/notifications-panel.component';
import * as FactorFeaturesStoreActions from '../../../root-store/factor-features-store/actions';
import { SidePanelComponent } from '../../components/side-panel/side-panel.component';
import { UserNotificationsStore } from '../../../notifications-module/stores';
import * as AppStoreSelectors from '../../../root-store/app-store/selectors';
import { TopBarComponent } from '../../components/top-bar/top-bar.component';
import { MenuACCComponent } from '../../components/menu/menu-acc.component';
import { MenuITSComponent } from '../../components/menu/menu-its.component';
import { UserClearanceLevel } from '../../../authentication-module/enums';
import * as AppStoreActions from '../../../root-store/app-store/actions';
import { environment } from '../../../../environments/environment';
import { IUserAvatar } from '../../models';

@Component({
	selector: 'master-layout',
	templateUrl: './master-layout.component.html',
	standalone: true,
	providers: [UserNotificationsStore],
	imports: [
		AsyncPipe,
		BreadcrumbComponent,
		ClickOutsideDirective,
		ScrolledByContainerDirective,
		DebugBarComponent,
		FeatureFlagsModule,
		MenuACCComponent,
		MenuITSComponent,
		NotificationsPanelComponent,
		RouterOutlet,
		SidePanelComponent,
		ThemesModule,
		TopBarComponent,
	],
})
export class MasterLayoutComponent extends UnsubscriberClass implements OnInit {
	private notifications = inject(UserNotificationsStore)
	private store = inject(Store)

	debugBar = viewChild(DebugBarComponent)
	menuCollapsed = false;

	currentLanguage$ = this.store.select(AppStoreSelectors.selectCurrentLanguage);
	currentUserAvatar$: Observable<IUserAvatar> = combineLatest([
		this.store.select(AppStoreSelectors.selectCurrentUser),
		this.store.select(AppStoreSelectors.selectCurrentUserAvatar),
	]).pipe(
		filter(([user]) => !!user),
		map(([_, avatar]) => avatar),
	);

	tenant$ = this.store.select(AppStoreSelectors.selectTenant);
	pageOverTitle$ = this.tenant$.pipe(map(tenant => tenant ? tenant.name : ''));
	pageOverLogo$ = this.store.select(AppStoreSelectors.selectTenantLogo);

	hasImportHistoryClearanceLevel: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.HistoryImport));
	hasManualImportClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.ManualImport));
	hasLetteringClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.Lettering));
	hasPaymentOrderClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.PaymentOrder));
	hasTeamFollowUpClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.TeamFollowUp));
	hasAdministrationToolsClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.AdministrationTools));
	hasSuperAdministrationClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.SuperAdministration));
	hasReadOnlyAccessToAllSuperDebtorsClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.ReadOnlyAccessToAllSuperDebtors));
	hasDunningClearanceLevel$: Observable<boolean> = this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.Dunning));

	themesIsOpened = false;
	notificationsIsOpened = false;
	notificationsIsOpened$ = toObservable(this.notifications.isPanelOpen);
	notificationsHasUnread$ = toObservable(this.notifications.hasUnread);

	// Use it to stop notifications refresh and avoid redux devtools flooding
	enableNotificationRefresh: boolean = environment.name !== 'Local';

	ngOnInit() {
		this.notificationsIsOpened$
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(isOpened => {
				this.themesIsOpened = false
				this.notificationsIsOpened = isOpened
			});

		this.store.dispatch(FactorFeaturesStoreActions.LoadFactorFeaturesRequest());
		this.notifications.loadNotificationList()
	}

	toggleNotificationPanel() {
		this.enableNotificationRefresh = true;
		this.notifications.togglePanel();

		// let's ask for a notification list right now.
		this.notifications.loadNotificationList();
	}

	toggleThemesPanel() {
		setTimeout(() => {
			this.notificationsIsOpened = false
			this.themesIsOpened = !this.themesIsOpened;
		}, 100)
	}

	handleAction(action: IAction, origin: 'menu' | 'notifications' | 'themes' = action.value) {
		switch (action.type) {
			case ActionType.OPEN:
				this.closePanels()
				break
			case ActionType.TOGGLE:
				if (origin === 'menu') {
					this.menuCollapsed = !this.menuCollapsed;
				} else if (origin === 'notifications') {
					this.toggleNotificationPanel();
				} else if (origin === 'themes') {
					this.toggleThemesPanel();
				}
				break;
		}
	}

	selectLanguage(language: AppLanguage) {
		this.store.dispatch(AppStoreActions.ChangeLanguage({ language }));
		this.store.dispatch(AppStoreActions.SetUserLanguageRequest({ language }));
	}

	logout() {
		const logoutAction = AppStoreActions.Logout()
		this.store.dispatch(AppStoreActions.OpenConfirmationModal({textsKey: `Modal.ConfirmationDeconnexion.`}, logoutAction));
	}

	closePanels() {
		this.themesIsOpened = false
		if (this.notificationsIsOpened) {
			this.notifications.closePanel();
		}
	}

	useTheme(theme: ITheme) {
		this.store.dispatch(UserPrefsStoreActions.UpdatePreferredTheme({theme}))
	}
}

